<script setup lang="ts">
import {useWindowScroll} from "@vueuse/core"
import {storeToRefs} from "pinia"
import {useUserStore} from "~/stores/user"

const { showSuggestions } = storeToRefs(useSearchStore())
const route = useRoute()
const { y: windowScroll } = useWindowScroll()
const { isAuthenticated } = storeToRefs(useUserStore())

interface Props {
  minimal?: boolean,
}

const props = withDefaults(defineProps<Props>(), {})

const stickyClass = computed(() => {
  if (route.meta.headerNotSticky) {
    return ''
  }

  return windowScroll.value > 0 ? 'lg:sticky lg:drop-shadow-sticky-header' : ''
})
</script>

<template>
  <LayoutsStack class="js-header transition bg-domain-dark top-0 relative z-10 p-bento -m-bento" :class="[stickyClass, isAuthenticated ? 'rounded-br-md' : 'rounded-b-md']">
    <template v-if="isAuthenticated">
      <LazyMoleculesHeaderAuthenticated />
      <LazyMoleculesSearchSuggestions v-if="showSuggestions" tabindex="0" data-search-trap />
    </template>
    <MoleculesHeaderAnonymous v-else :minimal="minimal" />
  </LayoutsStack>

  <MoleculesDialogDomainPicker />
  <MoleculesDialogLocalePicker />
</template>