<script setup lang="ts">
import type {TreeNodeData} from '~/types/TreeNodeData'
import {computed} from "vue"
import TreeNode from "~/components/atoms/TreeNode.vue"

interface Props {
  depth?: number,
  nodeData: TreeNodeData,
  expandedParents?: number[],
}

const props = withDefaults(defineProps<Props>(), {
  depth: 0,
})

const computedDepth = computed(() => {
  return props.depth ?? 0
})

const isRoot = computed(() => {
  return computedDepth.value === 0
})

const expanded = computed(() => {
  return isRoot.value || (props.nodeData.children && props.expandedParents && props.expandedParents.indexOf(props.nodeData.id) > -1)
})
</script>

<template>
  <NuxtLink class="block hover:text-domain cursor-pointer transition-colors text-button-small font-bold py-2"
            :class="computedDepth === 1 ? 'first:pt-0 last:pb-0' : ''"
            :active-class="'text-domain'"
            v-if="!isRoot"
            :to="localePath({name: 'node-id-slug', params: { id: nodeData.id.toString(), slug: nodeData.slug }})">
    {{ nodeData.name }}
  </NuxtLink>
  <div :class="isRoot ? '' : 'ltr:border-l-half-bento rtl:border-r-half-bento border-domain-dark ltr:pl-6 rtl:pr-6 my-2'" v-show="expanded">
    <component :is="TreeNode" v-for="child in nodeData.children" :key="child.id"
              :node-data="child" :depth="computedDepth + 1"
              :expanded-parents="expandedParents" />
  </div>
</template>