<script setup lang="ts">
import {useMediaListStore} from "~/stores/media-list"

const mediaListStore = useMediaListStore()
</script>

<template>
  <div class="p-16 pt-0 text-center">
    <AtomsButton :label="$t('media_feed.load_more')" hover-scheme="none" hover-text-color="domain" @click="mediaListStore.listNextPage" />
  </div>
</template>