<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain"
import {useMediaListStore} from "~/stores/media-list"
import {LayoutsMediaPack} from "#components"
import {storeToRefs} from 'pinia'
import {useNodeStore} from '~/stores/node'
import {useDoubleCardSize} from '~/composables/useMediaCardSize'
import {localeDirection} from '~/composables/useLocaleDirection'

const {domainData} = useDomainDataStore()
const mediaListStore = useMediaListStore()
const { currentNode } = storeToRefs(useNodeStore())

const pack = ref<typeof LayoutsMediaPack | null>(null)

watch(() => mediaListStore.listedMedias, () => {
  pack.value?.refreshLayout()
}, {flush: 'post'})
</script>

<template>
  <div class="grow">
    <LayoutsMediaPack ref="pack">
      <div v-if="!$route.meta.noMediaBrowserTree" class="u-pack-me p-half-bento" :class="useDoubleCardSize">
        <AtomsPackBox :dir="localeDirection">
          <AtomsTreeNode :node-data="domainData.tree" :expanded-parents="currentNode?.parents || []" />
        </AtomsPackBox>
      </div>
      <slot />
    </LayoutsMediaPack>
    <MoleculesLoadMoreMediaFooter v-if="mediaListStore.hasMoreMedias" />
  </div>
</template>