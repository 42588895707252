<script setup lang="ts">
import {PAGE_FADER_HIDE_REQUESTED, PAGE_FADER_SHOW_REQUESTED} from "~/types/bus-event-names"

const isVisible = ref(false)

useBusEvent(PAGE_FADER_SHOW_REQUESTED, onShowRequested)
useBusEvent(PAGE_FADER_HIDE_REQUESTED, onHideRequested)

function onShowRequested() {
  isVisible.value = true
}

function onHideRequested() {
  isVisible.value = false
}

const classes = computed(() => {
  return [
    "absolute",
    "top-0",
    "left-0",
    "w-full",
    "h-full",
    "bg-black",
    "z-page-fader",
    "opacity-80",
    "duration-500",
    "transition-opacity",
  ]
})

</script>

<template>
  <Transition enter-from-class="!opacity-0" leave-to-class="!opacity-0">
    <div v-if="isVisible" :class="classes"/>
  </Transition>
</template>